// @ts-nocheck
// This file is generated by Umi automatically
// DO NOT CHANGE IT MANUALLY!
import model_1 from '/opt/mangrove/leaf/src/.umi-production/plugin-initialState/@@initialState';
import model_2 from '/opt/mangrove/leaf/src/.umi-production/plugin-qiankun-slave/qiankunModel';

export const models = {
model_1: { namespace: '@@initialState', model: model_1 },
model_2: { namespace: '@@qiankunStateFromMaster', model: model_2 },
} as const
