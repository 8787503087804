// @ts-nocheck
// This file is generated by Umi automatically
// DO NOT CHANGE IT MANUALLY!
import DashboardOutlined from '/opt/mangrove/leaf/node_modules/.pnpm/@ant-design+icons@4.8.3_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/@ant-design/icons/es/icons/DashboardOutlined';
import UserOutlined from '/opt/mangrove/leaf/node_modules/.pnpm/@ant-design+icons@4.8.3_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/@ant-design/icons/es/icons/UserOutlined';
import AppstoreOutlined from '/opt/mangrove/leaf/node_modules/.pnpm/@ant-design+icons@4.8.3_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/@ant-design/icons/es/icons/AppstoreOutlined';
import WalletOutlined from '/opt/mangrove/leaf/node_modules/.pnpm/@ant-design+icons@4.8.3_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/@ant-design/icons/es/icons/WalletOutlined';
import OrderedListOutlined from '/opt/mangrove/leaf/node_modules/.pnpm/@ant-design+icons@4.8.3_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/@ant-design/icons/es/icons/OrderedListOutlined';
import SwapOutlined from '/opt/mangrove/leaf/node_modules/.pnpm/@ant-design+icons@4.8.3_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/@ant-design/icons/es/icons/SwapOutlined';
import CommentOutlined from '/opt/mangrove/leaf/node_modules/.pnpm/@ant-design+icons@4.8.3_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/@ant-design/icons/es/icons/CommentOutlined';
import TransactionOutlined from '/opt/mangrove/leaf/node_modules/.pnpm/@ant-design+icons@4.8.3_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/@ant-design/icons/es/icons/TransactionOutlined';
import MonitorOutlined from '/opt/mangrove/leaf/node_modules/.pnpm/@ant-design+icons@4.8.3_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/@ant-design/icons/es/icons/MonitorOutlined';
import SolutionOutlined from '/opt/mangrove/leaf/node_modules/.pnpm/@ant-design+icons@4.8.3_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/@ant-design/icons/es/icons/SolutionOutlined';
import ExceptionOutlined from '/opt/mangrove/leaf/node_modules/.pnpm/@ant-design+icons@4.8.3_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/@ant-design/icons/es/icons/ExceptionOutlined';
import ControlOutlined from '/opt/mangrove/leaf/node_modules/.pnpm/@ant-design+icons@4.8.3_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/@ant-design/icons/es/icons/ControlOutlined';
import AuditOutlined from '/opt/mangrove/leaf/node_modules/.pnpm/@ant-design+icons@4.8.3_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/@ant-design/icons/es/icons/AuditOutlined';
import BellOutlined from '/opt/mangrove/leaf/node_modules/.pnpm/@ant-design+icons@4.8.3_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/@ant-design/icons/es/icons/BellOutlined';
import AlertOutlined from '/opt/mangrove/leaf/node_modules/.pnpm/@ant-design+icons@4.8.3_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/@ant-design/icons/es/icons/AlertOutlined';
import NodeIndexOutlined from '/opt/mangrove/leaf/node_modules/.pnpm/@ant-design+icons@4.8.3_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/@ant-design/icons/es/icons/NodeIndexOutlined';
import DeploymentUnitOutlined from '/opt/mangrove/leaf/node_modules/.pnpm/@ant-design+icons@4.8.3_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/@ant-design/icons/es/icons/DeploymentUnitOutlined';
import ScheduleOutlined from '/opt/mangrove/leaf/node_modules/.pnpm/@ant-design+icons@4.8.3_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/@ant-design/icons/es/icons/ScheduleOutlined';
import SlidersOutlined from '/opt/mangrove/leaf/node_modules/.pnpm/@ant-design+icons@4.8.3_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/@ant-design/icons/es/icons/SlidersOutlined';
import UserSwitchOutlined from '/opt/mangrove/leaf/node_modules/.pnpm/@ant-design+icons@4.8.3_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/@ant-design/icons/es/icons/UserSwitchOutlined';
import TeamOutlined from '/opt/mangrove/leaf/node_modules/.pnpm/@ant-design+icons@4.8.3_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/@ant-design/icons/es/icons/TeamOutlined';
import ReadOutlined from '/opt/mangrove/leaf/node_modules/.pnpm/@ant-design+icons@4.8.3_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/@ant-design/icons/es/icons/ReadOutlined';
import MessageOutlined from '/opt/mangrove/leaf/node_modules/.pnpm/@ant-design+icons@4.8.3_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/@ant-design/icons/es/icons/MessageOutlined';
import FileOutlined from '/opt/mangrove/leaf/node_modules/.pnpm/@ant-design+icons@4.8.3_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/@ant-design/icons/es/icons/FileOutlined';
import BookOutlined from '/opt/mangrove/leaf/node_modules/.pnpm/@ant-design+icons@4.8.3_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/@ant-design/icons/es/icons/BookOutlined';
import SafetyOutlined from '/opt/mangrove/leaf/node_modules/.pnpm/@ant-design+icons@4.8.3_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/@ant-design/icons/es/icons/SafetyOutlined';
import KeyOutlined from '/opt/mangrove/leaf/node_modules/.pnpm/@ant-design+icons@4.8.3_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/@ant-design/icons/es/icons/KeyOutlined';
import SettingOutlined from '/opt/mangrove/leaf/node_modules/.pnpm/@ant-design+icons@4.8.3_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/@ant-design/icons/es/icons/SettingOutlined';
export default { DashboardOutlined, UserOutlined, AppstoreOutlined, WalletOutlined, OrderedListOutlined, SwapOutlined, CommentOutlined, TransactionOutlined, MonitorOutlined, SolutionOutlined, ExceptionOutlined, ControlOutlined, AuditOutlined, BellOutlined, AlertOutlined, NodeIndexOutlined, DeploymentUnitOutlined, ScheduleOutlined, SlidersOutlined, UserSwitchOutlined, TeamOutlined, ReadOutlined, MessageOutlined, FileOutlined, BookOutlined, SafetyOutlined, KeyOutlined, SettingOutlined };
