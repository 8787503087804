import { BellOutlined, CheckCircleFilled } from '@ant-design/icons';
import { DrawerForm } from '@ant-design/pro-components';
import { Link, useLocation } from '@umijs/max';
import { useBoolean, useSize } from 'ahooks';
import {
  Badge,
  Button,
  Flex,
  List,
  Modal,
  Radio,
  Select,
  Space,
  Tag,
  theme,
  Typography,
} from 'antd';
import VirtualList from 'rc-virtual-list';
import { LegacyRef, useEffect, useRef, useState } from 'react';
const { useToken } = theme;

export const GlobalNotification: React.FC<{
  typeOptions?: Record<string, any>[];
  confirmedMessages?: any;
  moreInfoPath?: string;
  getUnRead?: () => Promise<Record<string, any>>;
  appendData: (
    status,
    tagType,
    pageNum,
  ) => Promise<
    { title: string; tag: string; datetime: string; type: string }[]
  >;
}> = (properties) => {
  //drawer 开关
  const [drawerOpen, setDrawerOpen] = useBoolean();
  const [modalOpen, setModalOpen] = useBoolean();
  const { token } = useToken();
  const messageStateOptions = [
    { label: 'All', value: 'all' },
    { label: 'UnRead', value: 'unRead' },
  ];

  //当前Hover的List.Item
  const [currentHoverItem, setCurrentHoverItem] = useState(null);
  //当前Click的List.Item
  const [currentClickItem, setCurrentClickItem] = useState(null);
  //消息状态 所有 未读
  const [messageState, setMessageState] = useState('all');
  const [tagType, setTagType] = useState('0');
  const [totalUnRead, setTotalUnRead] = useState(0);
  const pageNum = useRef(1);

  //展示数据列表
  const [data, setData] = useState<Record<string, any>[]>([]);

  useEffect(() => {
    properties
      .appendData(messageState, tagType, pageNum.current)
      .then((tempData) => setData([...data, ...tempData]));
  }, [messageState, tagType, pageNum]);

  //获取滚动块高度
  const ref = useRef<LegacyRef<HTMLElement>>();
  const ContainerHeight = useSize(ref)?.height - 10;
  const currentPath = useLocation();

  useEffect(() => {
    if (properties.getUnRead) {
      properties
        .getUnRead()
        .then((data) =>
          setTotalUnRead(data?.data?.palmier?.userMessage?.retrieve?.total),
        );
    }
  }, [properties.getUnRead, currentPath]);

  return (
    <>
      <Modal
        width={'40vw'}
        open={modalOpen}
        destroyOnClose
        mask={false}
        footer={false}
        title={currentClickItem?.title}
        onCancel={() => setModalOpen.setFalse()}
      >
        <Typography>
          <Flex vertical>
            {currentClickItem?.owner && (
              <Tag>{currentClickItem?.owner?.name}</Tag>
            )}
            <Typography.Text style={{ marginBottom: '20px' }}>
              {currentClickItem?.description}
            </Typography.Text>
            <Typography.Text
              type="secondary"
              style={{
                fontSize: '13px',
                width: '100%',
                textAlign: 'right',
                marginBottom: '-20px',
              }}
            >
              {currentClickItem?.createdAt}
            </Typography.Text>
          </Flex>
        </Typography>
      </Modal>
      <DrawerForm
        trigger={
          <Badge count={totalUnRead} offset={[-3, 4]} size="small">
            <BellOutlined />
          </Badge>
        }
        width={380}
        style={{
          height: '100%',
        }}
        title="Message"
        open={drawerOpen}
        onChange={() => setDrawerOpen.setFalse()}
        extra={
          <Space>
            <Link to={properties.moreInfoPath ? properties.moreInfoPath : '#'}>
              <Typography.Text onClick={() => setDrawerOpen.setFalse()}>
                More Info
              </Typography.Text>
            </Link>
          </Space>
        }
        drawerProps={{
          styles: {
            body: {
              padding: '10px',
              marginTop: -5,
            },
          },
        }}
        submitter={false}
        {...properties}
      >
        <Flex
          vertical
          style={{ width: '100%', height: '100%' }}
          align={'start'}
        >
          <Flex
            style={{ width: '100%', height: '5%' }}
            justify={'space-around'}
            align={'center'}
          >
            <Radio.Group
              size={'small'}
              options={messageStateOptions}
              onChange={(e) => {
                setMessageState(e.target.value);
                pageNum.current = 1;
                setData([]);
              }}
              value={messageState}
              optionType="button"
            />
            <Select
              size={'small'}
              style={{ width: '110px' }}
              onSelect={(value) => {
                setTagType(value);
                pageNum.current = 1;
                setData([]);
              }}
              defaultValue="-1"
              options={properties.typeOptions ? properties.typeOptions : []}
            />
            <Button
              type="link"
              style={{ color: token.colorPrimary }}
              onClick={() =>
                properties.confirmedMessages &&
                properties
                  .confirmedMessages(
                    data.map((item) => {
                      return {
                        uuid: item.uuid,
                        confirmedAt: new Date(),
                      };
                    }),
                  )
                  .then(() =>
                    setData(
                      data.map((_item_) => {
                        _item_.confirmedAt = new Date();
                        return _item_;
                      }),
                    ),
                  )
              }
            >
              <CheckCircleFilled
                style={{ fontSize: '11px', marginRight: '3px' }}
              />
              Read All
            </Button>
          </Flex>
          <Flex
            vertical
            style={{ width: '100%', height: '100%', marginTop: '5px' }}
            ref={ref}
          >
            <List>
              <VirtualList
                data={data}
                height={ContainerHeight}
                itemHeight={47}
                onScroll={(e) =>
                  Math.abs(
                    e.currentTarget.scrollHeight -
                      e.currentTarget.scrollTop -
                      ContainerHeight,
                  ) <= 1 &&
                  properties
                    .appendData(messageState, tagType, (pageNum.current += 1))
                    .then((tempData) => setData([...data, ...tempData]))
                }
              >
                {(item) => (
                  <List.Item
                    onMouseEnter={() => setCurrentHoverItem(item)}
                    onMouseLeave={() => setCurrentHoverItem(null)}
                    style={{ width: '90%', margin: '0 10px' }}
                    onClick={() => {
                      setModalOpen.setTrue();
                      setCurrentClickItem(item);
                      if (!item.confirmedAt) {
                        properties
                          .confirmedMessages([
                            {
                              uuid: item.uuid,
                              confirmedAt: new Date(),
                            },
                          ])
                          .then(() =>
                            setData(
                              data.map((_item_) => {
                                if (_item_.uuid === item.uuid) {
                                  _item_.confirmedAt = new Date();
                                }
                                return _item_;
                              }),
                            ),
                          );
                      }
                    }}
                  >
                    <List.Item.Meta
                      title={
                        !item.confirmedAt &&
                        currentHoverItem &&
                        item.uuid === currentHoverItem.uuid ? (
                          <Flex justify={'space-between'} align={'center'}>
                            <Typography.Text
                              style={{ fontSize: '13px', width: '80%' }}
                              ellipsis
                            >
                              {item.title}
                            </Typography.Text>
                            <Button
                              type="link"
                              style={{ color: token.colorPrimary }}
                              onClick={() =>
                                properties.confirmedMessages &&
                                properties
                                  .confirmedMessages([
                                    {
                                      uuid: item.uuid,
                                      confirmedAt: new Date(),
                                    },
                                  ])
                                  .then(() =>
                                    setData(
                                      data.map((_item_) => {
                                        if (_item_.uuid === item.uuid) {
                                          _item_.confirmedAt = new Date();
                                        }
                                        return _item_;
                                      }),
                                    ),
                                  )
                              }
                            >
                              Read
                            </Button>
                          </Flex>
                        ) : (
                          <Typography.Text
                            style={{ fontSize: '13px', width: '100%' }}
                            ellipsis
                            type={item.confirmedAt && 'secondary'}
                          >
                            {item.title}
                          </Typography.Text>
                        )
                      }
                      description={
                        <Flex justify={'space-between'} align={'center'}>
                          <Tag color={item.color}>
                            <Typography.Text style={{ fontSize: '12px' }}>
                              {item.tag}
                            </Typography.Text>
                          </Tag>
                          <Typography.Text style={{ fontSize: '12px' }}>
                            {item.datetime}
                          </Typography.Text>
                        </Flex>
                      }
                    />
                  </List.Item>
                )}
              </VirtualList>
            </List>
          </Flex>
        </Flex>
      </DrawerForm>
    </>
  );
};
